import bruteScheme from "./bruteScheme";

export default {
  openNZZ: () => window.open("https://netzozeker.nl/", "_blank"),
  clean: () => (localStorage.clear(), window.location.reload()),
  updateWarningColor: function () {
    // assign designated color based on password strength
    let color = "#a03dd3";
    if (this.state.strength >= 10) color = "#d33d3d";
    if (this.state.strength >= 40) color = "#d37b3d";
    if (this.state.strength >= 80) color = "#d3ba3d";
    if (this.state.strength >= 100) color = "primary";
    this.state.color = color;
  },
  strengthEvaluator: function () {
    // runs the password strength checker script
    let strength = bruteScheme({
      password: this.password,
      options: this.options,
    });

    this.state.notice = strength.notice;
    this.state.strength = strength.percentage;
  },
  requestPassword: function () {
    if (this.isBusy) return;
    this.isBusy = true;

    // runs multiple times to give it the scrambling effect
    setTimeout(() => this.generatePassword(), 40);
    setTimeout(() => this.generatePassword(), 80);
    setTimeout(() => this.generatePassword(), (this.isBusy = false), 120);
    setTimeout(
      // Checks password strength and assigns strength color
      () => (this.strengthEvaluator(), this.updateWarningColor()),
      160
    );

    // Save cache locally for use later on
    localStorage.setItem(
      "cache",
      JSON.stringify({
        options: this.options,
        state: this.state,
      })
    );
  },
  generatePassword: function () {
    let res = "",
      charset = this.charset.default;

    // append chars to charset if specified options are met
    if (this.options.hasSymbols) charset += this.charset.symbols;
    if (this.options.hasNumbers) charset += this.charset.numbers;

    // create scrambled password based on charset
    for (var i = 0, n = charset.length; i < this.options.size; ++i)
      res += charset.charAt(Math.floor(Math.random() * n));

    // Pull random char from charset
    const randChar = (which) =>
      this.charset[which][
        Math.floor(Math.random() * this.charset[which]["length"])
      ];

    // Ensure atleast one char is inserted if below the length of 10, else insert atleast 3 symbols or/and 2 numbers if enabled in options.
    if (res.length < 10) {
      // Insert 1 char, if symbols or/and numbers are enabled.
      if (this.options.hasSymbols) res = (res + randChar("symbols")).slice(1);
      if (this.options.hasNumbers) res = (res + randChar("numbers")).slice(1);
    } else {
      // Shrink prepare string for char insertion
      res = res.slice(
        (this.options.hasSymbols ? 3 : 0) + (this.options.hasNumbers ? 2 : 0)
      );

      // Insert chars based on option selection
      if (this.options.hasNumbers)
        res = randChar("numbers") + res + randChar("numbers");
      if (this.options.hasSymbols) {
        res = randChar("symbols") + res + randChar("symbols");
        res =
          res.slice(0, res.length / 2) +
          randChar("symbols") +
          res.slice(res.length / 2);
      }
    }

    this.password = res;
  },
  copyToClipboard: function () {
    // prevent overwriting of password
    if (this.password.includes("ë")) return true;

    // write to clipboard
    const keep = this.password;
    navigator.clipboard.writeText(this.password).then(() => true);
    this.password = "Gekopiëerd naar klembord.";
    setTimeout(() => (this.password = keep), 750);
  },
};
