<template>
  <v-app style="background-color: #212121">
    <v-container>
      <v-img
        @click="openNZZ()"
        class="mt-2 clickable"
        style="margin-bottom: 75px"
        src="@/assets/logo.png"
        width="200"
      ></v-img>

      <h1 class="primary--text nzz-text-xxl nzz-font text--xl">
        MAAK EEN STERK WACHTWOORD AAN
      </h1>
      <p class="thin" style="margin-bottom: 75px">
        Gebruik onze wachtwoordgenerator om een sterk wachtwoord aan te maken.
      </p>

      <p
        class="clickable contained mb-0 pb-4 overflow-hidden"
        style="border-radius: 4px 4px 0 0; word-wrap: break-word"
        v-on:click="copyToClipboard"
        id="password"
      >
        {{ password }}
        <v-icon class="float-right" style="margin-top: 1.5px"
          >far fa-clone</v-icon
        >
      </p>
      <v-progress-linear
        :value="this.state.strength"
        :color="this.state.color"
        style="border-radius: 0 0 4px 4px"
        height="7.5"
        stream
      ></v-progress-linear>
      <p class="mt-2 thin">
        Dit wachtwoord is te kraken in
        <span class="primary--text">
          {{ this.state.notice }}
        </span>
      </p>

      <div class="contained pt-10 pb-8 mt-8">
        <p class="primary--text mb-0">Lengte ({{ this.options.size }})</p>
        <v-slider
          v-on:change="requestPassword()"
          v-model="options.size"
          hide-details
          :max="36"
          :min="4"
        >
        </v-slider>

        <v-row class="ml-0">
          <v-checkbox
            v-model="options.hasSymbols"
            v-on:change="requestPassword()"
            label="Symbolen"
          ></v-checkbox>

          <v-checkbox
            class="ml-5"
            v-model="options.hasNumbers"
            v-on:change="requestPassword()"
            label="Getallen"
          ></v-checkbox>
        </v-row>
      </div>

      <div class="text-center mt-10">
        <v-btn
          @click="requestPassword()"
          rounded
          color="primary"
          style="
            padding: 25px 30px 25px 30px;
            font-size: 20px;
            text-transform: none !important;
          "
          class="secondary--text text-weight-normal"
          >Wachtwoord Genereren</v-btn
        >
      </div>
    </v-container>

    <v-spacer />

    <v-container
      style="background-color: #0f3e26; border-radius: 4px; margin-top: 75px"
      class="py-5 mb-5 px-10 primary--text"
    >
      <v-row>
        <v-icon color="primary">fal fa-shield-check </v-icon>
        <v-col>
          Dit wachtwoord wordt eenmalig gegenereerd en niet opgeslagen.
          Deze pagina is enkel bedoeld om u te helpen met uw online veiligheid.
        </v-col>
      </v-row>
    </v-container>

    <v-footer height="75" style="background-color: #0a0c10; color: #fff">
      <v-container>
        <v-row align="center">
          <v-col justify="center">
            <v-img
              @click="openNZZ()"
              class="clickable"
              src="@/assets/logo-wit.png"
              width="100"
            ></v-img>
          </v-col>
          <v-col class="text-right clickable">
            <p
              class="mb-0 font-weight-bold"
              style="font-size: 17.5px"
              @click="clean()"
            >
              © {{ this.date }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import vueMethods from "./utils/vueMethods";
import vueData from "./utils/vueData";
import vueMounted from "./utils/vueMounted";

export default {
  name: "App",
  data: vueData,
  methods: vueMethods,
  mounted: vueMounted,
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Khand:wght@300&display=swap");

.nzz-font {
  font-family: "Khand", sans-serif;
}
.nzz-text-xxl {
  font-size: 55px;
}

.v-slider--horizontal {
  margin: 0;
}
.clickable:hover {
  cursor: pointer;
}
.thin {
  font-weight: 300;
}
.contained {
  padding: 20px 40px 19px 40px;
  margin: 0;

  border-radius: 4px;

  font-size: 20px;

  background-color: #4e504e;
}
</style>
