import charset from "../../charset.json";

export default () => ({
  charset,
  password: "",
  options: {
    size: 13,
    hasSymbols: true,
    hasNumbers: true,
  },
  state: {
    notice: "2 miljoen jaar",
    color: "primary",
    strength: 100,
    isBusy: false,
  },
  date: "2021", // non static, will change to current year automatically.
});
