import chart_base from "./chart/base.json";
import chart_single from "./chart/single.json";
import chart_both from "./chart/both.json";

export default ({ password, options }) => {
  const size = password.length;
  let strength = {};

  // fetch password strength data from .json files.
  const set = (chart) => {
    if (size in chart) strength = chart[size];
    else if (size > 18) strength = chart["above"];
    else strength = chart["4"];
    strength.type = chart.type;
  };

  // call the above function based on specified options.
  if (options.hasSymbols && options.hasNumbers) set(chart_both);
  else if (options.hasSymbols || options.hasNumbers) set(chart_single);
  else set(chart_base);

  return strength;
};
